import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    allowedUrls: Array
  }

  handleMessage(event) {
    if (this.originIsInvalid(event)) {
      console.warn("Invalid origin for postMessage")
      return
    }

    let promise = this.takeAction(event.data)

    if (promise != null){
      promise.done((response) => {
        event.source.postMessage({
          action: `${event.data.action} response`,
          response: response
        }, event.origin)
      }).fail(() => {
        event.source.postMessage({
          action: `${event.data.action} response`,
          response: {
            result: 'Failed', message: "Something went wrong.", errors: []
          }
        }, event.origin)
      })
    }
  }

  takeAction(message) {
    switch (message.action) {
      case 'create order':
        return window.OrderAPI.create(message.orderData)
      case 'orders app frame position':
        return this.framePosition()
      case 'orders app height':
        this.updateFrameHeight(message.height)
        return null
    }
  }

  updateFrameHeight(height) {
    if (height != null) {
      $(this.element).css('height', height)
    }
  }

  framePosition() {
    let deferred = $.Deferred()

    deferred.resolve(this.element.getBoundingClientRect())

    return deferred.promise()
  }

  originIsInvalid(event) {
    return !this.allowedUrlsValue.includes(event.origin)
  }
}
